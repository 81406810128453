import { FC, useEffect } from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export const Tooltip = (
  message: string,
  duration: number = 5000,
  type: "error" | "info" | "success",
) => {
  return toast[type](message, {
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
  });
};

interface AlertProps {
  message: string;
  duration?: number;
  type?: "error" | "info" | "success";
}

export const Alert: FC<AlertProps> = (props) => {
  const { message, type = "info", duration = 3000, ...otherProps } = props;

  useEffect(() => {
    toast(message, {
      type,
      autoClose: duration,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...otherProps,
    });
  }, [message]);

  return null;
};
