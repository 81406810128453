import WebApp from "@twa-dev/sdk";
import { observer } from "mobx-react-lite";
import { FC, ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useStore } from "shared/hooks";
import { AppRoutesWithProps, RoutePath } from "shared/types/routes";

interface RouteProtectorProps {
  route: AppRoutesWithProps;
  children: ReactNode;
}

export const RouteProtector: FC<RouteProtectorProps> = observer((props) => {
  const { route, children } = props;
  const { profileStore } = useStore();
  const { profile } = profileStore;
  const { notAuthOnly, authOnly, roles, useBackButton } = route;

  if (!profile?.user?.id && authOnly) {
    return <Navigate to={RoutePath.main} />;
  }

  if (profile?.user?.id && route.notAuthOnly) {
    return <Navigate to={RoutePath.user} />;
  }

  return <>{children}</>;
});
