import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AppRoutesWithProps } from "shared/types/routes";
import { routeConfig } from "shared/routes/routes";
import { RouteProtector } from "./RouteProtector";
import useMining from "shared/hooks/useMining";

export const RouteProvider = observer(() => {
  const renderWrapper = (options: AppRoutesWithProps) => {
    const { path, element } = options;

    const renderRoute = (
      <RouteProtector route={options}>
        <Suspense>{element}</Suspense>
      </RouteProtector>
    );

    return <Route key={path} path={path} element={renderRoute} />;
  };

  useMining();

  return <Routes>{Object.values(routeConfig).map(renderWrapper)}</Routes>;
});
