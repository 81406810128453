import { action, makeObservable, observable } from "mobx";
import React, { useContext } from "react";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export class RegisterStore {
  constructor() {
    makeObservable<RegisterStore>(this, {
      step: observable,
      setStep: action,
      searchNftText: observable,
      setSearchNftText: action,
      nfts: observable,
      setNfts: action,
      currentNfts: observable,
      setCurrentNfts: action,
      searchJettonText: observable,
      setSearchJettonText: action,
      jettons: observable,
      setJettons: action,
      currentJettons: observable,
      setCurrentJettons: action,
      loading: observable,
      setLoading: action,
    });
  }
  step: number = 1;
  setStep = (number: number) => {
    this.step = number;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  };
  loading: boolean = false;
  setLoading = (status: boolean) => {
    this.loading = status;
  };
  searchNftText: string = "";
  setSearchNftText = (str: string) => {
    this.searchNftText = str;
  };
  nfts: {
    img: string;
    id: number;
  }[] = [
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 0,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 1,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 2,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 3,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/2/88/bmw-bmv-e30-mashina-cars-les.jpg",
      id: 4,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/d/cc/2015-bmw-6-series-coupe-bmv.jpg",
      id: 5,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/c/cf/bmw-z4-m40i-g29-seryi-rodster-2019-nebo-fon.jpg",
      id: 6,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/3/a2/bmw-z4-m40i-g29-rodster-seryi-2019-doroga-vid-speredi-rastit.jpg",
      id: 7,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/a/e9/bmw-z4-m40i-g29-seryi-rodster-2019-stoianka-derevia.jpg",
      id: 8,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 9,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 10,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 11,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 12,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/2/88/bmw-bmv-e30-mashina-cars-les.jpg",
      id: 13,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/d/cc/2015-bmw-6-series-coupe-bmv.jpg",
      id: 14,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/c/cf/bmw-z4-m40i-g29-seryi-rodster-2019-nebo-fon.jpg",
      id: 15,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/3/a2/bmw-z4-m40i-g29-rodster-seryi-2019-doroga-vid-speredi-rastit.jpg",
      id: 16,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/a/e9/bmw-z4-m40i-g29-seryi-rodster-2019-stoianka-derevia.jpg",
      id: 17,
    },
  ];
  setNfts = (
    items: {
      img: string;
      id: number;
    }[],
  ) => {
    this.nfts = items;
  };
  currentNfts: number[] = [];
  setCurrentNfts = (items: number[]) => {
    this.currentNfts = items;
  };
  searchJettonText: string = "";
  setSearchJettonText = (str: string) => {
    this.searchJettonText = str;
  };

  jettons: {
    img: string;
    id: number;
    name: string;
    descr: string;
  }[] = [
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 0,
      name: "Raff",
      descr: "Ton Raffles Token",
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 1,
      name: "PUNK",
      descr: "$PUNK",
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 2,
      name: "jUSDT",
      descr: "Tether USD",
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 3,
      name: "TGRAM",
      descr: "Tgram",
    },
  ];
  setJettons = (
    items: {
      img: string;
      id: number;
      name: string;
      descr: string;
    }[],
  ) => {
    this.nfts = items;
  };
  currentJettons: number[] = [];
  setCurrentJettons = (items: number[]) => {
    this.currentJettons = items;
  };
}

const RegisterContext = React.createContext<RegisterStore | undefined>(
  undefined,
);

export const useRegisterContext = () => {
  const value = useContext(RegisterContext);
  if (value === undefined) {
    throw new Error("Context not found");
  }
  return value;
};

type Props = {
  children: React.ReactNode;
  store: RegisterStore;
};

export function RegisterProvider({ children, store }: Props) {
  return (
    <RegisterContext.Provider value={store}>
      {children}
    </RegisterContext.Provider>
  );
}

export const registerStore = new RegisterStore();
