import { action, makeObservable, observable } from "mobx";
import React, { useContext } from "react";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export class ProfileStore {
  constructor() {
    makeObservable<ProfileStore>(this, {
      customLinks: observable,
      setCustomLinks: action,
      addCustomLink: action,
      genders: observable,
      countries: observable,
      genderCurrent: observable,
      setGenderCurrent: action,
      genderInProfile: observable,
      setGenderInProfile: action,
      countryCurrent: observable,
      setСountryCurrent: action,
      dateOfBirth: observable,
      setDateOfBirth: action,
      dateOfBirthInProfile: observable,
      setDateOfBirthInProfile: action,
      userStatus: observable,
      setUserStatus: action,
      discordUrl: observable,
      setDiscordUrl: action,
      twitterUrl: observable,
      setTwitterUrl: action,
      instagramUrl: observable,
      setInstagramUrl: action,
      searchNftText: observable,
      setSearchNftText: action,
      nfts: observable,
      setNfts: action,
      currentNfts: observable,
      setCurrentNfts: action,
      searchJettonText: observable,
      setSearchJettonText: action,
      jettons: observable,
      setJettons: action,
      currentJettons: observable,
      setCurrentJettons: action,
      statuses: observable,
      statusesCurrent: observable,
      setStatusesCurrent: action,
      statusesInProfile: observable,
      setStatusesInProfile: action,
      tgUsernameInProfile: observable,
      setTgUsernameInProfile: action,
      inviterInProfile: observable,
      setInviterInProfile: action,
    });
  }

  tgUsernameInProfile: boolean = true;
  setTgUsernameInProfile = (status: boolean) => {
    this.tgUsernameInProfile = status;
  };

  inviterInProfile: boolean = true;
  setInviterInProfile = (status: boolean) => {
    this.inviterInProfile = status;
  };

  customLinks: { title: string; url: string }[] = [];
  setCustomLinks = (links: { title: string; url: string }[]) => {
    this.customLinks = links;
  };

  addCustomLink = (title: string, url: string) => {
    this.customLinks.push({ title, url });
  };
  statuses: string[] = [
    "💼 Investor",
    "🗿 Holder",
    "📈 Trader",
    "🤑 Crypto maniac",
    " 👨‍🏫 Can help beginners",
    "🤓 Beginner",
    "🏴 Enthusiast",
  ];
  statusesCurrent: number[] = [0];
  setStatusesCurrent = (number: number) => {
    if (this.statusesCurrent.includes(number)) {
      this.statusesCurrent = [...this.statusesCurrent].filter(
        (item, index) => index !== [...this.statusesCurrent].indexOf(number),
      );
    } else {
      this.statusesCurrent.push(number);
    }
  };
  statusesInProfile: boolean = true;
  setStatusesInProfile = (status: boolean) => {
    this.statusesInProfile = status;
  };
  genders: string[] = [
    "Female",
    "Male",
    "Non-binary",
    "Cisgender",
    "Cryptosexual",
    "Crypto trans",
    "LGBT+",
    "Meme coin",
    "Autophilia",
  ];
  countries: string[] = ["Russia", "USA", "OAE"];
  genderCurrent: number | null = 1;
  setGenderCurrent = (num: number) => {
    this.genderCurrent = num;
  };
  genderInProfile: boolean = true;
  setGenderInProfile = (status: boolean) => {
    this.genderInProfile = status;
  };
  countryCurrent: number | null = 1;
  setСountryCurrent = (num: number) => {
    this.countryCurrent = num;
  };
  dateOfBirth: Value | null = new Date();
  setDateOfBirth = (num: Value) => {
    this.dateOfBirth = num;
  };
  dateOfBirthInProfile: boolean = true;
  setDateOfBirthInProfile = (status: boolean) => {
    this.dateOfBirthInProfile = status;
  };
  userStatus: string = "Check the TON rate to finally exit the crypto";
  setUserStatus = (str: string) => {
    this.userStatus = str;
  };
  discordUrl: string = "";
  setDiscordUrl = (str: string) => {
    this.discordUrl = str;
  };
  twitterUrl: string = "";
  setTwitterUrl = (str: string) => {
    this.twitterUrl = str;
  };
  instagramUrl: string = "";
  setInstagramUrl = (str: string) => {
    this.instagramUrl = str;
  };
  searchNftText: string = "";
  setSearchNftText = (str: string) => {
    this.searchNftText = str;
  };
  nfts: {
    img: string;
    id: number;
  }[] = [
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 0,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 1,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 2,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 3,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/2/88/bmw-bmv-e30-mashina-cars-les.jpg",
      id: 4,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/d/cc/2015-bmw-6-series-coupe-bmv.jpg",
      id: 5,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/c/cf/bmw-z4-m40i-g29-seryi-rodster-2019-nebo-fon.jpg",
      id: 6,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/3/a2/bmw-z4-m40i-g29-rodster-seryi-2019-doroga-vid-speredi-rastit.jpg",
      id: 7,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/a/e9/bmw-z4-m40i-g29-seryi-rodster-2019-stoianka-derevia.jpg",
      id: 8,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 9,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 10,
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 11,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 12,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/2/88/bmw-bmv-e30-mashina-cars-les.jpg",
      id: 13,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/d/cc/2015-bmw-6-series-coupe-bmv.jpg",
      id: 14,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/c/cf/bmw-z4-m40i-g29-seryi-rodster-2019-nebo-fon.jpg",
      id: 15,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/3/a2/bmw-z4-m40i-g29-rodster-seryi-2019-doroga-vid-speredi-rastit.jpg",
      id: 16,
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/a/e9/bmw-z4-m40i-g29-seryi-rodster-2019-stoianka-derevia.jpg",
      id: 17,
    },
  ];
  setNfts = (
    items: {
      img: string;
      id: number;
    }[],
  ) => {
    this.nfts = items;
  };
  currentNfts: number[] = [];
  setCurrentNfts = (items: number[]) => {
    this.currentNfts = items;
  };
  searchJettonText: string = "";
  setSearchJettonText = (str: string) => {
    this.searchJettonText = str;
  };

  jettons: {
    img: string;
    id: number;
    name: string;
    descr: string;
  }[] = [
    {
      img: "https://img.goodfon.ru/original/1600x900/f/89/bmw-z4-m40i-g29-seryi-rodster-2019-doroga-poberezhe-nebo.jpg",
      id: 0,
      name: "Raff",
      descr: "Ton Raffles Token",
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/8/7a/bmw-m2-competition-2018-f87-kupe-gazon-asfalt-trassa-skorost.jpg",
      id: 1,
      name: "PUNK",
      descr: "$PUNK",
    },
    {
      img: "https://img.goodfon.ru/original/1600x900/d/b5/german-special-customs-bmw-i8.jpg",
      id: 2,
      name: "jUSDT",
      descr: "Tether USD",
    },
    {
      img: "https://img.goodfon.ru/original/1920x1080/b/6e/bmw-e90-white-bmv-3-seriya.jpg",
      id: 3,
      name: "TGRAM",
      descr: "Tgram",
    },
  ];
  setJettons = (
    items: {
      img: string;
      id: number;
      name: string;
      descr: string;
    }[],
  ) => {
    this.nfts = items;
  };
  currentJettons: number[] = [];
  setCurrentJettons = (items: number[]) => {
    this.currentJettons = items;
  };
}

const ProfileContext = React.createContext<ProfileStore | undefined>(undefined);

export const useProfileContext = () => {
  const value = useContext(ProfileContext);
  if (value === undefined) {
    throw new Error("Context not found");
  }
  return value;
};

type Props = {
  children: React.ReactNode;
  store: ProfileStore;
};

export function ProfileProvider({ children, store }: Props) {
  return (
    <ProfileContext.Provider value={store}>{children}</ProfileContext.Provider>
  );
}

export const profileStore = new ProfileStore();
