import CoinIcon from "shared/ui/Icons/CoinIcon/CoinIcon";
import { Link } from "react-router-dom";
import formatTime from "shared/methods/formatTime";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { RoutePath } from "shared/types/routes";
import { MiningDataType } from "entities/Mining";
import { ReactComponent as BurnIcon } from "shared/assets/svg/burn.svg";
import { ReactComponent as BoostMiningArrow } from "shared/assets/svg/boostMiningArrow.svg";
import { miningPageStore } from "../../store/miningPageStore";
import { clsx } from "clsx";
import { Skeleton } from "shared/ui/Skeleton";

import s from "./Mining.module.scss";

interface MiningProps {
  mining: MiningDataType;
  miningBurn: number;
  miningClaim: number;
}

export const Mining: FC<MiningProps> = observer((props) => {
  const { mining, miningBurn, miningClaim } = props;

  const { claimMiningFetch, postMiningClaim } = miningPageStore;

  const handleClaimClick = () => {
    if (claimMiningFetch?.state !== "pending") {
      postMiningClaim();
    }
  };

  const classes: Record<string, boolean> = {
    [s.claimButton_disable]: miningClaim < 2,
  };

  return (
    <div className={s.center}>
      <div className={s.claim}>
        <div className={s.claim_top}>
          <div className={s.claim_top_l}>
            <div className={s.claim_top_img}>
              <CoinIcon type="background" />
            </div>
            {!claimMiningFetch && (
              <div className={s.claim_top_text}>
                <p>To claim:</p>
                <p>{miningClaim.toFixed(4)} XP</p>
              </div>
            )}
            {claimMiningFetch?.case({
              fulfilled: () => (
                <div className={s.claim_top_text}>
                  <p>To claim:</p>
                  <p>{miningClaim.toFixed(4)} XP</p>
                </div>
              ),
              pending: () => (
                <Skeleton style={{ width: "120px", height: "20px" }} />
              ),
            })}
          </div>
          <div
            className={clsx(s.claimButton, classes)}
            onClick={handleClaimClick}
          >
            Claim
          </div>
        </div>
        <div className={s.claim_bottom}>
          <BurnIcon />
          <span>Burn in:</span>
          &nbsp;
          {!claimMiningFetch && <span>{formatTime(miningBurn)}</span>}
          {claimMiningFetch?.case({
            fulfilled: () => <span>{formatTime(miningBurn)}</span>,
            pending: () => (
              <Skeleton style={{ width: "120px", height: "20px" }} />
            ),
          })}
        </div>
      </div>
      <Link to={RoutePath.boost} className={s.btn}>
        <BoostMiningArrow />
        Boost mining
      </Link>
      <div className={s.balance}>
        <p>You balance:</p>
        <p>
          <CoinIcon type="no-background" />
          {parseFloat(mining.balance).toFixed(2)}
        </p>
      </div>
    </div>
  );
});
