import { API } from "shared/api";
import { miningStore } from "../store/miningStore";
import {
  BURN_TIMEOUT_MILISECONDS,
  MAX_MINING_TIME_HOURS,
  MILISECONDS_IN_HOUR,
  MILISECONDS_IN_SECONDS,
} from "shared/hooks/useMining";

const errorMessage = "Error while request mining data";

export const fetchMining = async () => {
  try {
    const { setMiningData, setMinigClaim, setMiningBurn } = miningStore;

    const response = await API.get(`https://mining.tonid.app/api/user`);

    if (!response) {
      throw new Error(errorMessage);
    }

    const MILISECONDS_LAST_CLAIM =
      Date.now() - Number(response.data.user.last_claim);

    if (MILISECONDS_LAST_CLAIM >= BURN_TIMEOUT_MILISECONDS) {
      const CLAIMED_TOKENS =
        MAX_MINING_TIME_HOURS * response.data.user.speed_mining;
      const BURN_VALUE_AFTER_BURNING = 0;

      setMinigClaim(CLAIMED_TOKENS);
      setMiningData(response.data.user);
      setMiningBurn(BURN_VALUE_AFTER_BURNING);
      return;
    }

    const HOURS_AFTER_LAST_CLAIM = MILISECONDS_LAST_CLAIM / MILISECONDS_IN_HOUR;
    const CLAIMED_TOKENS =
      HOURS_AFTER_LAST_CLAIM * response.data.user.speed_mining;
    const BURN_VALUE_AFTER_BURNING =
      (BURN_TIMEOUT_MILISECONDS - MILISECONDS_LAST_CLAIM) /
      MILISECONDS_IN_SECONDS;
    setMinigClaim(CLAIMED_TOKENS);
    setMiningData(response.data.user);
    setMiningBurn(BURN_VALUE_AFTER_BURNING);
    return;
  } catch {
    throw new Error(errorMessage);
  }
};
