import { observer } from "mobx-react-lite";
import s from "./FirstStart.module.scss";
import useFirstStart from "shared/hooks/useFirstStart";
import { Btn } from "shared/ui/Btn";

const FirstStart: React.FC = () => {
  const { nextClick, btnText, btnType, imgSrc, title, text } = useFirstStart();
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.img}>
          <img src={imgSrc} alt="" />
        </div>
        <div className={s.title}>{title}</div>
        <div className={s.text}>{text}</div>
      </div>
      <Btn className={s.btn} typeButton={btnType} onClick={nextClick}>
        {btnText}
      </Btn>
    </div>
  );
};

export default observer(FirstStart);
