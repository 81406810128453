import { RouteProps } from "react-router-dom";
import { UserRolesEnum } from "shared/routes/routes";

export enum AppRoutes {
  USER = "user",
  HOME = "home",
  MAIN = "main",
  EDIT_PROFILE = "editProfile",
  FILLING_PROFILE = "fillingProfile",
  MINING = "mining",
  BOOST = "boost",
  TASK_BY_ID = "taskByID",
  CONNECT = "connect",
  CLAIM_BY_ID = "claimByID",
  SELECT_FAVORITE_TOKENS = "selectFavoriteTokens",
  NOT_FOUND = "notFound",
}

export type AppRoutesWithProps = RouteProps & {
  roles: UserRolesEnum[];
  useBackButton?: boolean;
  authOnly?: boolean;
  notAuthOnly?: boolean;
};

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/",
  [AppRoutes.USER]: "/user",
  [AppRoutes.HOME]: "/home",
  [AppRoutes.EDIT_PROFILE]: "/profile-edit",
  [AppRoutes.FILLING_PROFILE]: "/filling-profile/",
  [AppRoutes.MINING]: "/mining",
  [AppRoutes.BOOST]: "/boost",
  [AppRoutes.TASK_BY_ID]: "/task/", // + id
  [AppRoutes.CONNECT]: "/connect",
  [AppRoutes.CLAIM_BY_ID]: "/claim/", // + id
  [AppRoutes.SELECT_FAVORITE_TOKENS]: "/select-favorite-tokens",
  [AppRoutes.NOT_FOUND]: "*",
};
