import FirstStart from "../../pages/FirstStart/FirstStart";
import { AppRoutes, AppRoutesWithProps, RoutePath } from "shared/types/routes";
import { BoostPage } from "pages/BoostPage";
import { HomePage } from "pages/HomePage";
import { UserPage } from "pages/UserPage";
import { ProfileEditPage } from "pages/ProfileEditPage";
import { MiningPage } from "pages/MiningPage";
import { TaskByIdPage } from "pages/TaskByIdPage";
import { ConnectWalletPage } from "pages/ConnectWalletPage";
import { ClaimPage } from "pages/ClaimPage";
import { SelectFavoriteTokensPage } from "pages/SelectFavoriteTokens";
import { NotFoundPage } from "pages/NotFoundPage";
import { FillingProfilePage } from "pages/FillingProfilePage";

export enum UserRolesEnum {
  UNDEFINED = "notAuth",
  USER = "withAccount",
  ROOT = "withConnectedWallet",
}

export const routeConfig: Record<AppRoutes, AppRoutesWithProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <FirstStart />,
    roles: [UserRolesEnum.UNDEFINED],
    notAuthOnly: true,
  },
  [AppRoutes.HOME]: {
    path: RoutePath.home,
    element: <HomePage />,
    roles: [UserRolesEnum.USER, UserRolesEnum.ROOT],
    authOnly: true,
  },
  [AppRoutes.USER]: {
    path: RoutePath.user,
    element: <UserPage />,
    roles: [UserRolesEnum.USER, UserRolesEnum.ROOT],
    authOnly: true,
  },
  [AppRoutes.EDIT_PROFILE]: {
    path: RoutePath.editProfile,
    element: <ProfileEditPage />,
    roles: [UserRolesEnum.ROOT, UserRolesEnum.USER],
    authOnly: true,
    useBackButton: true,
  },
  [AppRoutes.MINING]: {
    path: RoutePath.mining,
    element: <MiningPage />,
    roles: [UserRolesEnum.ROOT, UserRolesEnum.USER],
    authOnly: true,
  },
  [AppRoutes.BOOST]: {
    path: RoutePath.boost,
    element: <BoostPage />,
    roles: [UserRolesEnum.ROOT, UserRolesEnum.USER],
    authOnly: true,
  },
  [AppRoutes.TASK_BY_ID]: {
    path: RoutePath.taskByID + ":id",
    element: <TaskByIdPage />,
    roles: [UserRolesEnum.ROOT, UserRolesEnum.USER],
    authOnly: true,
  },
  [AppRoutes.CONNECT]: {
    path: RoutePath.connect,
    element: <ConnectWalletPage />,
    roles: [UserRolesEnum.USER],
    authOnly: true,
  },
  [AppRoutes.CLAIM_BY_ID]: {
    path: RoutePath.claimByID + ":id",
    element: <ClaimPage />,
    roles: [UserRolesEnum.ROOT, UserRolesEnum.USER],
    authOnly: true,
  },
  [AppRoutes.SELECT_FAVORITE_TOKENS]: {
    path: RoutePath.selectFavoriteTokens,
    element: <SelectFavoriteTokensPage />,
    roles: [UserRolesEnum.ROOT],
    authOnly: true,
  },
  [AppRoutes.FILLING_PROFILE]: {
    path: RoutePath.fillingProfile + "*",
    element: <FillingProfilePage />,
    roles: [UserRolesEnum.USER, UserRolesEnum.ROOT],
    authOnly: true,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.notFound,
    element: <NotFoundPage />,
    roles: [],
  },
};
