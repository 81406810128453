import { Tooltip } from "shared/ui/Tooltip";
import { API } from "shared/api";
import { miningStore } from "entities/Mining";
import {
  BURN_TIMEOUT_MILISECONDS,
  MILISECONDS_IN_HOUR,
  MILISECONDS_IN_SECONDS,
} from "shared/hooks/useMining";

export const claimMining = async () => {
  try {
    const response = await API.post("https://mining.tonid.app/api/user/claim");
    const user = response.data.user[0];
    const { setMiningData, setMiningBurn, setMinigClaim } = miningStore;

    const MILISECONDS_LAST_CLAIM = Date.now() - Number(user.last_claim) > 0 ? Date.now() - Number(user.last_claim) : 100;
    const HOURS_AFTER_LAST_CLAIM = MILISECONDS_LAST_CLAIM / MILISECONDS_IN_HOUR;
    const CLAIMED_TOKENS = HOURS_AFTER_LAST_CLAIM * user.speed_mining;
    const MINING_BURN = (BURN_TIMEOUT_MILISECONDS - MILISECONDS_LAST_CLAIM) / MILISECONDS_IN_SECONDS

    setMiningBurn(MINING_BURN);
    setMinigClaim(CLAIMED_TOKENS);
    setMiningData(user);
    return response.data.user[0];
  } catch (error: any) {
    const { mining } = miningStore;
    Tooltip(error.response.data.error, 1500, "info");
    return mining;
  }
};
