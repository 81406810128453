import JsonWebToken from "./JsonWebToken";
import { Account } from "@tonconnect/ui-react";
import Cookies from "js-cookie";
import { API } from "shared/api";

class RequestServiceMining {
  static async createUserMining() {
    const body = {
      ref_id: -1,
    };
    const response = await API.post(
      "https://mining.tonid.app/api/user/create",
      body,
    );
    return response;
  }

  static async getMining(): Promise<any> {
    return API.get("https://mining.tonid.app/api/user/mining")
      .then((data) => data)
      .catch(async (error) => {
        if (error.response.data.error === "user not found") {
          const response = await RequestService.createUserMining();
          if (response.status === 200) {
            return RequestService.getMining();
          } else {
            throw response;
          }
        } else {
          throw error;
        }
      });
  }

  static async claim() {
    const { data } = await API.post("https://mining.tonid.app/api/user/claim");
    return data;
  }

  static async claimTask(id: string | number) {
    try {
      const body = { id: id };
      const response = await API.post(
        "https://mining.tonid.app/api/task/claim",
        body,
      );
      return response;
    } catch {
      return undefined;
    }
  }

  static async getTasks() {
    const { data } = await API.get("https://mining.tonid.app/api/task");
    return data;
  }

  static async getUserMining(): Promise<any> {
    const response = API.get(`https://mining.tonid.app/api/user`);
    return response;
    // return response.data;
  }
}

export default class RequestService extends RequestServiceMining {
  static async getGenders() {
    const { data } = await API.get("/genders");
    return data;
  }

  static async getStatuses() {
    const { data } = await API.get("/statuses");
    return data;
  }

  static async editProfile(profile: {
    profile_color?: string;
    avatar?: string;
    name?: string;
    status?: string;
    genderId?: number;
    dateOfBirth?: string;
    location?: string;
    bio?: string;
    custom_links?: { [key: string]: string };
    social_links?: { discord: string; twitter: string; instagram: string };
    display_username?: boolean;
    display_inviters?: boolean;
    display_gender?: boolean;
    display_profile?: boolean;
    display_dateofbirth?: boolean;
    display_status?: boolean;
  }) {
    const { data } = await API.post("/user/editProfile", profile);
    return data;
  }

  static async getInviteProfile() {
    const { data } = await API.get("/user/invite");
    return data;
  }

  static async getLastTransaction() {
    const { data } = await API.get(`/web3/profile/lastTransaction`);
    return data;
  }

  static async refreshSession() {
    const refreshKey = Cookies.get("refreshToken"); //JsonWebToken.getRefreshKey();
    const response = await API.get(`/session/refreshPayload/${refreshKey}`);

    return response.data.payload;
  }

  static async recoverySession() {
    try {
      const response = await API.get(`/session`);

      return response.data;
    } catch (e) {}
  }

  static async verify(body: {
    account: Account;
    connectItems: {
      tonProof: {
        proof: {
          domain: {
            lengthBytes: number;
            value: string;
          };
          signature: string;
          payload: string;
          timestamp: number;
        };
      };
    };
  }) {
    const { data } = await API.post(`/web3/verify`, body);
    return data;
  }

  static async createUser() {
    //user: { tg_chatid: number }
    const { data } = await API.post("/user/create");

    return data;
  }

  static async createUserWeb3() {
    const { data } = await API.post("/web3/create");
    return data;
  }

  static async chooseGender(body: { genderId: number }) {
    const { data } = await API.post("/user/changeGender", body);
    return data;
  }

  static async getFavoritesNfts() {
    const response = await API.get(`/web3/nfts/favorite`);
    return response.data;
  }

  static async getNfts() {
    const response = await API.get(`/web3/nfts`);
    return response.data;
  }

  static async getFavoritesJettons() {
    const response = await API.get(`/web3/jettons/favorite`);
    return response.data;
  }

  static async getJettons() {
    const response = await API.get(`/web3/jettons`);
    return response.data;
  }

  static async getUser() {
    const accessKey = JsonWebToken.getAccessKey();
    if (!accessKey) throw "User not found";

    const payload = JsonWebToken.parseJwt(accessKey as string);

    const response = await API.get(`/user/profile/${payload}`);
    return response.data;
  }
}
