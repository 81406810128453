import { makeAutoObservable } from "mobx";
import { MiningDataType } from "../types/miningTypes";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { fetchMining } from "../api/fetchMining";

class MiningStore {
  mining: MiningDataType | null = null;
  miningFetch?: IPromiseBasedObservable<void>;
  claimRequest?: IPromiseBasedObservable<void>;
  miningBurn: number = 0;
  miningClaim: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fetchMiningData = () => {
    this.miningFetch = fromPromise(fetchMining());
  };

  setMiningData = (mining: MiningDataType) => {
    this.mining = mining;
  };

  setMiningBurn = (burn: number) => {
    this.miningBurn = burn;
  };

  setMinigClaim = (claim: number) => {
    this.miningClaim = claim;
  };
}

export const miningStore = new MiningStore();

export type MiningStoreSchema = typeof miningStore;
