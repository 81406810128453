import { Buffer } from "buffer";

export default function convertBufferToBase64(buffer: string) {
  if (typeof buffer === "object") {
    const base64 = `${Buffer.from(buffer ? buffer : "", "base64")}`;
    return `data:image/png;base64,${base64.replace("data:image/png;base64,", "")}`;
  } else {
    return buffer;
  }
}
