import { makeAutoObservable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { claimMining } from "../api/claimMining";

class MiningPageStore {
  claimMiningFetch?: IPromiseBasedObservable<void>;

  constructor() {
    makeAutoObservable(this);
  }

  postMiningClaim = () => {
    this.claimMiningFetch = fromPromise(claimMining());
  };
}

export const miningPageStore = new MiningPageStore();
