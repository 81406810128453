"use client";

import { Link } from "react-router-dom";
import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

import s from "./Btn.module.scss";

type ButtonHeight = "small" | "auto" | string;
type ButtonWidth = "auto" | string;

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  blank?: boolean;
  typeButton?: "light-blue" | "" | "white" | "light-white";
  type?: "submit" | "reset" | "button" | undefined;
  height?: ButtonHeight;
  width?: ButtonWidth;
  fontSize?: string;
  fontWeight?: string;
  disabled?: boolean;
}

const getHeightStyle = (height: ButtonHeight) =>
  ({
    small: "45px",
    auto: "100%",
  })[height] || `${height}px`;

const getWidthStyle = (width: ButtonWidth) =>
  ({
    auto: "100%",
    full: "100%",
  })[width] || `${width}px`;

export const Btn: FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    href,
    blank,
    typeButton = "default",
    type = "button",
    onClick,
    height = "52",
    width = "full",
    fontSize = "16",
    fontWeight = "700",
    disabled = false,
    style: buttonStyle = {},
    ...otherProps
  } = props;

  const style = {
    height: getHeightStyle(height),
    width: getWidthStyle(width),
    fontSize: `${fontSize}px`,
    fontWeight: fontWeight,
    ...buttonStyle,
  };

  const classes: Record<string, boolean> = {
    [s.disabled]: disabled,
    [s.lightBlue]: typeButton === "light-blue",
    [s.lWhite]: typeButton === "light-white",
    [s.white]: typeButton === "white",
  };

  const anchorProps = {
    onClick,
    className: clsx(s.btn, className, classes),
    style: style,
    ...(blank && { target: "_blank", rel: "noopener noreferrer" }),
    type: type,
  };

  return href ? (
    <Link to={href}>
      <button {...anchorProps} {...otherProps}>
        {children}
      </button>
    </Link>
  ) : (
    <button {...anchorProps} {...otherProps}>
      {children}
    </button>
  );
};
