import { FC, HTMLAttributes, useEffect } from "react";
import clsx from "clsx";
import { useStore } from "shared/hooks";
import formatLastTransaction from "shared/methods/formatLastTransaction";
import { ProfilePreviewSkeleton } from "../ProfilePreviewSkeleton/ProfilePreviewSkeleton";
import { observer } from "mobx-react-lite";
import { Avatar } from "shared/ui/Avatar";
import { Alert } from "shared/ui/Tooltip";
import { ProfileType } from "entities/Profile";

import s from "./ProfilePreview.module.scss";

interface ProfilePreviewProps extends HTMLAttributes<HTMLDivElement> {
  profile: ProfileType;
  className?: string;
}

const last_transaction = 0;

export const ProfilePreview: FC<ProfilePreviewProps> = observer((props) => {
  const { profile, className } = props;

  const { user } = profile;

  return (
    <div className={s.info_l}>
      <Avatar text={user.name} imgBase64={user.avatar} />
      <div className={clsx(s.info_text, className)}>
        <p>{user.name}</p>
        {/* <p>Last transaction: 2 min ago</p> */}
        {!last_transaction ? (
          <p>Wallet not connected</p>
        ) : (
          <p>{formatLastTransaction(last_transaction)}</p>
        )}
        {/*<p>{formatLastTransaction(last_transaction)}</p>*/}
      </div>
    </div>
  );
});
