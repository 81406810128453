import { FC, HTMLAttributes } from "react";
import clsx from "clsx";

import s from "./Skeleton.module.scss";

export interface SkeletonProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Skeleton: FC<SkeletonProps> = (props) => {
  const { className, ...otherProps } = props;

  return <div className={clsx(s.skeleton, className)} {...otherProps} />;
};
