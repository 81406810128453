import { FC, ReactNode } from "react";

import { StoreContext } from "../lib/storeContext";
import { RootStoreSchema } from "../types/storeSchema";

interface StoreContextProviderProps {
  children: ReactNode;
  initialValue: RootStoreSchema;
}

export const StoreContextProvider: FC<StoreContextProviderProps> = (props) => {
  const { children, initialValue } = props;

  return (
    <StoreContext.Provider value={initialValue}>
      {children}
    </StoreContext.Provider>
  );
};
