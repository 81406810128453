import { makeAutoObservable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { EditProfileFields, fetchProfile } from "../api/fetchProfile";
import { ProfileType } from "../types/profileTypes";

class ProfileStore {
  profile: ProfileType | null = null;
  profileFetch?: IPromiseBasedObservable<void>;

  constructor() {
    makeAutoObservable(this);
  }

  fetchProfileData = () => {
    this.profileFetch = fromPromise(fetchProfile());
  };

  setProfile = (profile: ProfileType) => {
    this.profile = profile;
  };

  fetchEditProfileData = (EditProfileFields: EditProfileFields) => {
    // this.profile = null
    // fromPromise(fetchEditProfile(EditProfileFields));
  };
}

export const profileStore = new ProfileStore();

export type ProfileStoreSchema = typeof profileStore;
