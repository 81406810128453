import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RequestService from "services/RequestService";
import JsonWebToken from "services/JsonWebToken";
import { RoutePath } from "shared/types/routes";

const useFirstStart = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);

  const btnText = step === 1 ? "Continue" : "Okay";
  const btnType: "" | "light-blue" = step === 1 ? "light-blue" : "";
  const imgSrc = step === 1 ? "./hello.png" : "./duckling.png";
  const title = step === 1 ? "Welcome to TON ID!" : "Easy start";
  const text =
    step === 1
      ? "Your new unified Web3 and Web2 profile"
      : "Complete your profile and share to earn rewards!";

  const nextClick = async () => {
    if (step === 1) {
      setStep(step + 1);
    } else {
      /*   const data = {
        tg_chatid: WebApp.initDataUnsafe.user?.id as number,
      };*/
      const { mining, profile } = await RequestService.createUser();

      //   setSession(payload.accessKey);

      //   await RequestService.createUserMining();

      navigate(RoutePath.home);
      /*
      RequestService.createUser()
        .then((data) => {
          setSession(data.payload.accessKey);
        })
        .catch((error) => {
          console.error(error)
          if(error.response.status === 409 && error.response.data.error === 'User already exists') {
            const accessKey = JsonWebToken.getAccessKey();
            setSession(accessKey as string);
            navigate("/home")
          }
        });
      RequestService.createUserMining()
        .then((data) => {
          navigate("/home");
        });*/
    }
  };

  return { nextClick, btnText, btnType, imgSrc, title, text };
};

export default useFirstStart;
