import { MiningDataType } from "entities/Mining";

export default class JsonWebToken {
  static parseJwt(token: string): MiningDataType {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }

  static getAccessKey() {
    return localStorage.getItem("accessKey");
  }

  static getRefreshKey() {
    return localStorage.getItem("refreshKey");
  }

  static saveAccessKey(accessKey: string) {
    localStorage.setItem("accessKey", accessKey);
  }

  static saveRefreshKey(refreshKey: string) {
    localStorage.setItem("refreshKey", refreshKey);
  }
}
