import { action, makeObservable, observable } from "mobx";
import React, { useContext } from "react";

interface TaskItem {
  id: number | null;
  description: string;
  executions_per_cycle: number | null;
  image: string;
  name: string;
  reward: string;
  task_type: number | null;
  link: string;
}

export class ModalsStore {
  constructor() {
    makeObservable<ModalsStore>(this, {
      profileInfoStatus: observable,
      setProfileInfoStatus: action,
      taskModal: observable,
      setTaskModal: action,
      isFadeOut: observable,
      setIsFadeOut: action,
      shareStatus: observable,
      setShareStatus: action,
      taskItem: observable,
      setTaskItem: action,
      selectedTask: observable,
      setSelectedTask: action,
    });
  }
  taskItem: TaskItem = {
    id: null,
    description: "",
    executions_per_cycle: null,
    image: "",
    name: "",
    reward: "",
    task_type: null,
    link: "",
  };
  setTaskItem = (item: TaskItem) => {
    this.taskItem = item;
  };
  profileInfoStatus: boolean = false;
  setProfileInfoStatus = (status: boolean) => {
    this.profileInfoStatus = status;
  };
  selectedTask: number = -1;
  setSelectedTask = (taskId: number) => {
    this.selectedTask = taskId;
  };
  taskModal: boolean = false;
  setTaskModal = (status: boolean) => {
    this.taskModal = status;
    if (status === false) {
      this.setTaskItem({
        id: null,
        description: "",
        executions_per_cycle: null,
        image: "",
        name: "",
        reward: "",
        task_type: null,
        link: "",
      });
    }
  };
  shareStatus: boolean = false;
  setShareStatus = (status: boolean) => {
    this.shareStatus = status;
  };
  isFadeOut: boolean = false;
  setIsFadeOut = (status: boolean) => {
    this.isFadeOut = status;
  };
}

const ModalsContext = React.createContext<ModalsStore | undefined>(undefined);

export const useModalsContext = () => {
  const value = useContext(ModalsContext);
  if (value === undefined) {
    throw new Error("Context not found");
  }
  return value;
};

type Props = {
  children: React.ReactNode;
  store: ModalsStore;
};

export function ModalsProvider({ children, store }: Props) {
  return (
    <ModalsContext.Provider value={store}>{children}</ModalsContext.Provider>
  );
}

export const modalsStore = new ModalsStore();
