import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import React, { useContext } from "react";
import JsonWebToken from "../services/JsonWebToken";

export class MiningStore {
  constructor() {
    makeObservable<MiningStore>(this, {
      mining: observable,
      setClaim: action,
      setBurn: action,
    });
  }

  mining: {
    claim: number;
    burn: number;
  } = this.initialization();

  private initialization() {
    return {
      claim: 0,
      burn: 0,
    };
  }

  setClaim = (claim: number) => {
    this.mining.claim = claim;
  };

  setBurn = (burn: number) => {
    this.mining.burn = burn;
  };
}

const MiningContext = React.createContext<MiningStore | undefined>(undefined);

export const useMiningContext = () => {
  const value = useContext(MiningContext);
  if (value === undefined) {
    throw new Error("Context not found");
  }
  return value;
};

type Props = {
  children: React.ReactNode;
  store: MiningStore;
};

export function MiningProvider({ children, store }: Props) {
  return (
    <MiningContext.Provider value={store}>{children}</MiningContext.Provider>
  );
}

export const miningStore = new MiningStore();
