import WebApp from "@twa-dev/sdk";
import { FC, useEffect, useRef } from "react";
import {
  RootStoreSchema,
  StoreContextProvider,
} from "app/providers/StoreProvider";
import { useNavigate } from "react-router-dom";
import { RouteProvider } from "app/providers/RouteProvider";
import { observer } from "mobx-react-lite";
import { profileStore } from "entities/Profile";
import { Preloader } from "shared/ui/Preloader";
import { miningStore } from "entities/Mining";

import s from "./App.module.scss";

const initialValue: RootStoreSchema = {
  profileStore: profileStore,
  miningStore: miningStore,
};

const APPLICATION_BACKGROUND_COLOR = "#fff";

const App: FC = observer(() => {
  const navigate = useNavigate();

  const { fetchMiningData } = miningStore;
  const { profileFetch, fetchProfileData } = profileStore;

  const isMounted = useRef<boolean>(false);

  const handleClickBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!isMounted.current) {
      WebApp.setBackgroundColor(APPLICATION_BACKGROUND_COLOR);
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => WebApp.BackButton.hide());
      WebApp.onEvent("backButtonClicked", handleClickBackButton);
      WebApp.expand();
      isMounted.current = true;
    }
    fetchProfileData();
    fetchMiningData();
  }, []);

  return (
    <>
      {profileFetch?.case({
        fulfilled: () => (
          <StoreContextProvider initialValue={initialValue}>
            <div className={s.wrapper}>
              <RouteProvider />
            </div>
          </StoreContextProvider>
        ),
        rejected: () => <Preloader />,
        pending: () => <Preloader />,
      })}
    </>
  );
});

export default App;
