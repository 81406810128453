import { useEffect, useRef, useState } from "react";
import { useStore } from "./useStore";
import { API } from "shared/api";

export const INTERVAL_REQUEST_TIME = 10000;
export const BURN_TIMEOUT_MILISECONDS = 43200000;
export const MILISECONDS_IN_HOUR = 3600000;
export const TIMER_INTERVAL_TIME = 1000;
export const MILISECONDS_IN_SECONDS = 1000;
export const MAX_MINING_TIME_HOURS = 12;

const useMining = () => {
  const { miningStore } = useStore();
  const {
    mining,
    miningBurn,
    miningClaim,
    setMiningData,
    setMiningBurn,
    setMinigClaim,
  } = miningStore;

  const [isMiningTimeout, setIsMiningTimeout] = useState<boolean>(false);
  const isMiningStarted = useRef<boolean>(false);
  const requestTimeInterval = useRef<NodeJS.Timer | null>(null);
  const claimInterval = useRef<NodeJS.Timer | null>(null);
  const burnInterval = useRef<NodeJS.Timer | null>(null);

  const fetchUserMining = async () => {
    const response = await API.get("https://mining.tonid.app/api/user/mining");

    const MILISECONDS_LAST_CLAIM = Date.now() - Number(response.data.mining.last_claim);
    const HOURS_AFTER_LAST_CLAIM = MILISECONDS_LAST_CLAIM / MILISECONDS_IN_HOUR;
    const CLAIMED_TOKENS = HOURS_AFTER_LAST_CLAIM * response.data.mining.speed_mining;

    if (MILISECONDS_LAST_CLAIM >= BURN_TIMEOUT_MILISECONDS) {
      setIsMiningTimeout(true);
    }

    setMiningData(response.data.mining);
    setMinigClaim(CLAIMED_TOKENS);
    setMiningBurn(
      (BURN_TIMEOUT_MILISECONDS - MILISECONDS_LAST_CLAIM) / MILISECONDS_IN_SECONDS,
    );
  };

  useEffect(() => {
    if (isMiningTimeout && requestTimeInterval.current) {
      clearInterval(requestTimeInterval.current);
    }

    if (isMiningTimeout) {
      setIsMiningTimeout(true)
      return;
    }

    if (!isMiningStarted.current && mining && mining?.last_claim <= BURN_TIMEOUT_MILISECONDS) {
      requestTimeInterval.current = setInterval(() => {
        fetchUserMining();
      }, INTERVAL_REQUEST_TIME);
      isMiningStarted.current = true;
    }
    return () => {
      if (requestTimeInterval.current) {
        clearInterval(requestTimeInterval.current);
      }
    };
  }, [isMiningTimeout]);

  useEffect(() => {
    if (mining) {
      const MILISECONDS_LAST_CLAIM = Date.now() - Number(mining.last_claim) > 0 ? Date.now() - Number(mining.last_claim) : 500;
      const HOURS_AFTER_LAST_CLAIM = MILISECONDS_LAST_CLAIM / MILISECONDS_IN_HOUR;

      if (MILISECONDS_LAST_CLAIM < BURN_TIMEOUT_MILISECONDS) {
        const CLAIMED_TOKENS = HOURS_AFTER_LAST_CLAIM * mining.speed_mining;
        claimInterval.current = setTimeout(
          () => {
            setMinigClaim(CLAIMED_TOKENS)
          },
          TIMER_INTERVAL_TIME,
        );
      }
    }

    if (claimInterval.current) {
      return () => clearTimeout(claimInterval.current as NodeJS.Timer);
    }
  }, [miningClaim, mining]);

  useEffect(() => {
    if (mining) {
      const MILISECONDS_LAST_CLAIM = Date.now() - Number(mining.last_claim) > 0 ? Date.now() - Number(mining.last_claim) : 500;

      if (MILISECONDS_LAST_CLAIM < BURN_TIMEOUT_MILISECONDS) {
        const BURN_VALUE_AFTER_BURNING = (BURN_TIMEOUT_MILISECONDS - MILISECONDS_LAST_CLAIM) / MILISECONDS_IN_SECONDS;
        burnInterval.current = setTimeout(
          () => setMiningBurn(BURN_VALUE_AFTER_BURNING),
          TIMER_INTERVAL_TIME,
        );
      }
    }

    if (burnInterval.current) {
      return () => clearTimeout(burnInterval.current as NodeJS.Timer);
    }
  }, [miningBurn, mining]);
};

export default useMining;
