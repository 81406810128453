import WebApp from "@twa-dev/sdk";
import axios from "axios";

export const API = axios.create({
  withCredentials: false,
  baseURL: "https://api384000771.tonid.app/api", // добавь это потом в .env => proccess.env.baseURLAPI
  headers: {
    "init-data": WebApp.initData
  },
});
