import React from "react";
import classes from "./avatar.module.scss";
import Fancybox from "./components/Fancybox/Fancybox";
import convertBufferToBase64 from "shared/methods/convertBufferToBase64";

interface Props {
  imgBase64?: string | undefined;
  text: string;
  type?: "small" | "medium" | "large";
}

export const Avatar: React.FC<Props> = ({
  imgBase64,
  text,
  type = "small",
}) => {
  const styleType =
    type === "small"
      ? { width: "45px", height: "45px" }
      : type === "medium"
        ? { width: "70px", height: "70px" }
        : { width: "80px", height: "80px" };

  return (
    <div style={styleType} className={classes.avatar}>
      {imgBase64 ? (
        <Fancybox
          options={{
            contentClick: "close",
            Toolbar: {
              display: {
                left: [],
                middle: [],
                right: ["close"],
              },
            },
            animated: false,
            closeButton: false,
          }}
        >
          <img
            data-fancybox="gallery"
            src={convertBufferToBase64(imgBase64)}
            alt=""
          />
        </Fancybox>
      ) : (
        `${text[0]}`
      )}
    </div>
  );
};
