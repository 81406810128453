import RequestService from "services/RequestService";
import { API } from "shared/api";
import { Tooltip } from "shared/ui/Tooltip";
import { profileStore } from "../store/profileStore";

const errorMessage = "Ошибка при запросе данных профиля";

export const fetchProfile = async () => {
  try {
    const response = await API.get(`/user/profile`);

    if (!response) {
      throw new Error(errorMessage);
    }

    response.data.social_links = response.data.social_links || [];

    profileStore.setProfile(response.data);
  } catch {
    throw new Error(errorMessage);
  }
};

export type EditProfileFields = Parameters<
  typeof RequestService.editProfile
>[0];
export const fetchEditProfile = async (fields: EditProfileFields) => {
  try {
    const data = await RequestService.editProfile(fields);
    return data;
  } catch (error: any) {
    Tooltip(error.response.data.error, 2500, "error");
  }
};
