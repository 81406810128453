import ReactDOM from "react-dom/client";
import App from "./app/ui/App";
import { RegisterProvider, registerStore } from "./stores/register-store";
import { ModalsProvider, modalsStore } from "./stores/modals-store";
import { ProfileProvider, profileStore } from "./stores/profile-store";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MiningProvider, miningStore } from "./stores/mining-store";
import WebApp from "@twa-dev/sdk";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "app/providers/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <ErrorBoundary>
    <TonConnectUIProvider
      manifestUrl={`https://ton-connect.github.io/demo-dapp-with-react-ui/tonconnect-manifest.json`}
      restoreConnection={true}
      language="en"
    >
      <BrowserRouter>
        <ModalsProvider store={modalsStore}>
          <RegisterProvider store={registerStore}>
            <ProfileProvider store={profileStore}>
              <MiningProvider store={miningStore}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </MiningProvider>
            </ProfileProvider>
          </RegisterProvider>
        </ModalsProvider>
      </BrowserRouter>
    </TonConnectUIProvider>
    <ToastContainer />
  </ErrorBoundary>,
);
